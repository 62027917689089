<template>
    <div class="container">
      <content-title :nav="nav"></content-title>
      <el-row>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="手机号">
            <el-input></el-input>
          </el-form-item>
          <el-form-item label="订单渠道">
          <el-select v-model="source" clearable>
            <el-option
              v-for="(item, index) in sourceList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
            <el-select v-model="status">
              <el-option label="已支付" :value="2"></el-option>
              <el-option label="待支付" :value="0"></el-option>
              <el-option label="已取消" :value="8"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="timeDate"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
            <el-button type="success" @click="opendislog">充值</el-button>
          </el-form-item>
        </el-form>
  
        <el-table
          :data="pointList"
          :fit="true"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          v-loading="is_loading"
        >
        <el-table-column align="center" label="订单号" width="150">
          <template v-slot="{ row }">
            <p class="textell">{{ row.flowing_no }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="用户昵称">
          <template v-slot="{ row }">
            <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }"> {{ row.map.userinfo.nick_name }}</link-to>
          </template>
        </el-table-column>
          <el-table-column
            label="用户手机号"
            prop="map.userinfo.mobile"
          ></el-table-column>
          <el-table-column
            label="充值权益点数"
            prop="course_num"
          ></el-table-column>
          <el-table-column label="付款金额" prop="amount"></el-table-column>
          <el-table-column
          prop="pay_type"
          align="center"
          label="付款方式"
          :formatter="Paytype"
        ></el-table-column>
        <el-table-column
          prop="source"
          align="center"
          label="订单渠道"
          :formatter="Paysource"
        ></el-table-column>
          <el-table-column
            label="交易创建时间"
            width="200"
            prop="rcharge_time"
          ></el-table-column>
          <el-table-column prop="city" align="center" label="线下收款图片">
            <template slot-scope="{ row }">
              <div class="flex-col">
                <el-image
                  v-if="row.offline_collection_img"
                  style="width: 88px; height: 49px"
                  :src="row.offline_collection_img"
                  :preview-src-list="[row.offline_collection_img]"
                ></el-image>
                <span v-if="row.status == 0" style="color: #409eff" @click="openCollection(row)"
                  >补录</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span
                v-if="scope.row.status == 0"
                style="color: red"
                >待付款</span
              >
              <span v-else-if="scope.row.status == 2" style="color: green"
                >已付款</span
              >
              <span v-else-if="scope.row.status == 1" style="color: orange"
                >待付款</span
              >
              <span v-else-if="scope.row.status == 3" style="color: green"
                >退款审核中</span
              >
              <span v-else-if="scope.row.status == 4" style="color: green"
                >已退款</span
              >
              <span v-if="scope.row.status == 8" style="color: #409eff"
                >已取消</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status == 2"
                type="danger"
                size="small"
                @click="refoud(scope.row)"
                >退款
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
          <template v-slot="{ row }">
            <link-to :to="'orderDetails/' + row.id">
              <p>详情</p>
            </link-to>
          </template>
        </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="total"
        ></paging-fy>
      </el-row>
      <el-dialog :visible="refundReason" title="退款">
        <el-row style="display: flex" v-if="refundReason">
          <label>退款理由</label>
          <el-input
            type="textarea"
            style="width: 50%"
            v-model="reason"
          ></el-input>
        </el-row>
  
        <span slot="footer" class="dialog-footer">
          <el-button type="warning" @click="confirmRefund(temp)">确 定</el-button>
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :visible="refuseReason || showReason"
        :title="refuseReason ? '拒绝退款' : showReason ? '拒绝理由' : ''"
      >
        <el-row style="display: flex" v-if="refuseReason">
          <label>拒绝理由</label>
          <el-input
            type="textarea"
            style="width: 50%"
            v-model="refuse_contect"
          ></el-input>
        </el-row>
        <el-row style="display: flex" v-if="showReason">
          <label>理由</label><span>{{ temp.refuse_contect }}</span>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="warning"
            v-if="refuseReason"
            @click="update(temp.id, 5)"
            >确 定</el-button
          >
          <el-button @click="handleClose" v-if="refuseReason">取 消</el-button>
          <el-button @click="showReason = false" v-if="showReason"
            >关闭</el-button
          >
        </span>
      </el-dialog>
      <!-- 充值 -->
      <el-dialog :visible="payShow" title="充值">
        <el-form label-width="80px" :model="Recharge">
          <el-form-item label="充值用户">
            <el-select
              v-model="r_user"
              filterable
              remote
              reserve-keyword
              placeholder="请输入用户电话"
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <el-option
                v-for="item in userList"
                :key="item.user_id"
                :label="item.nick_name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="充值金额">
            <el-input
              v-model="Recharge.count"
              type="number"
              placeholder="请输入充值金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="Recharge.reason"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款凭证">
            <avatar-uploader
              @getImgUrl="Uploadpayimg"
              :clear="true"
              :url="Recharge.payimg"
            >
            </avatar-uploader>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="payShow = false">取 消</el-button>
          <el-button type="primary" @click="submitRecharge()">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisible" :destroy-on-close="true" width="400px" title="补录线下收款">
        <el-form>
          <el-form-item label="收款金额">
            <el-input v-model="price" placeholder="请输入收款金额"></el-input>
          </el-form-item>
          <el-form-item label="线下收款图片">
            <avatar-uploader @getImgUrl="getpic" :url="pic"></avatar-uploader>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="collection"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        nav: { firstNav: "财务管理中心", secondNav: "权益点充值订单" },
        mechanism_id: null,
        mechanism_list: [],
        date: null,
        pointList: [],
        status: null,
        is_loading: true,
        loading: false,
        refuseReason: false,
        refundReason: false,
        showReason: false,
        refuse_contect: "",
        currentPage: 1,
        total: 0,
        reason: "",
        temp: {},
        //chongzhi
        userList: [],
        payShow: false,
        r_user: {},
        Recharge: {
          count: "",
          reason: "",
          payimg: "",
        },
        timeDate: [],
      formInline: {
        user: "",
        region: "",
      },
      sourceList: [
        {
          value: 'sport',
          label: "微信小程序",
        },
        {
          value: 'dy_sport',
          label: "抖音小程序",
        },
        {
          value: 'android',
          label: "安卓APP",
        },
        {
          value: 'ios',
          label: "ios APP",
        },
        {
          value: 'pc',
          label: "pc",
        },
      ],
      pic: "",
      price:'',
      curOrder:{},
      dialogVisible:false
      };
    },
    mounted() {
      this.getRefoundList();
    },
    methods: {
      collection(){
        if(this.price == '' || this.pic == ''){
          this.$message('请填写收款资料')
          return
        }
        if(this.price > this.curOrder.amount){
          this.$message('补录金额不能大于订单金额')
          return
        }
        this.$axios({
          url:'user/pay/offlineCollection',
          data:{
            flowing_no : this.curOrder.flowing_no,
            offline_collection : this.price,
            offline_collection_img : this.pic
          },
          method:'post'
        }).then(res=>{
          this.$message(res.data.message)
          if(res.data.code == 0){
            this.getRefoundList();
             this.dialogVisible = false
          }
        })
      },
      openCollection(row){
         this.dialogVisible = true
         this.curOrder = row
      },
        Paytype(row, column) {
      let type = row.pay_type;
      if (type == "ali") {
        return "支付宝";
      } else if (type == "wx") {
        return "微信";
      } else if (type == "point") {
        return "权益点";
      } else if (type == "dy") {
        return "抖音";
      } else {
        return type;
      }
    },
    Paysource(row) {
      let source = row.source;
      let pay = row.pay_type;
      if (source == "sport" && pay == "dy") {
        return "抖音小程序";
      } else if (source == "sport" && pay != "dy") {
        return "微信小程序";
      } else {
        return source;
      }
    },
      //充值
      submitRecharge() {
        let url = "/user/rechargerecord/updateImage";
        let data = {
          mechanism_name: "",
          nick_name: "",
        };
      },
      remoteMethod(query) {
        if (query != "") {
          let url = "/user/user/queryByMessage";
          let data = {
            is_teach_paypal: true,
            login_name: query,
            register_platform: "wx",
          };
          this.$axios
            .get(url, {
              params: {
                ...data,
              },
            })
            .then((res) => {
              this.userList = res.data.data.rows;
            });
        }
      },
      search(){
        this.currentPage = 1
        this.pointList = []
        this.getRefoundList()
    },
    changeCurrentPage(v){
        this.currentPage = v
        this.getRefoundList()
    },
    //查询订单
    getRefoundList() {
      this.is_loading = true;
      let url = "/user/rechargerecord/queryByMessage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        rcharge_type: "point",
        is_teach_paypal: true,
      };
      if (this.status !== "") {
        params.status = this.status;
      }
      if(this.source){
        if(this.source == 'dy_sport'){
            params.source = 'sport'
            params.pay_type = 'dy'
        }else{
            params.source = this.source
        }
      }
      
      if (this.timeDate && this.timeDate.length == 2) {
        params.start_time = this.timeDate[0]; //时间
        params.end_time = this.timeDate[1];
      }
      if (this.formInline.region) {
        params.login_name = this.formInline.region; //手机号
      }
      if (this.formInline.user) {
        params.mechanism_name = this.formInline.user; //机构名
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.total = res.data.data.total;
          this.pointList = res.data.data.rows;
          this.is_loading = false;
        });
    },
    getpic(v) {
      this.pic = v;
    },
      opendislog() {
        this.payShow = true;
      },
      refuse(row) {
        this.temp = row;
        this.refuseReason = true;
      },
      showRefuse(row) {
        console.log(row);
        this.temp = row;
        this.showReason = true;
      },
      getMechanismList(query) {
        let url = "/user/mastermechanism/queryMechanismByType";
        this.$axios
          .get(url, {
            params: {
              // type: 'teach_paypal',
              categories: "体育运动",
              source: "体育宝",
              mechanism_name:
                typeof query == "string" && query != "" ? query : null,
              pageSize: 99,
              currentPage: 1,
            },
          })
          .then((res) => {
            // console.log(res);
            this.mechanism_list = res.data.data;
          });
      },
      update(id, status) {
        console.log(this.temp);
        let url = "/user/rechargerecordrefund/updateRefund";
        this.$axios
          .post(url, {
            id: id,
            status: status,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({ type: "warning", message: "修改成功!" });
              this.getPointList();
              this.refuseReason = false;
            } else {
              this.$message({ type: "warning", message: res.data.message });
            }
          });
      },
      refoud(row) {
        this.temp = row;
        this.refundReason = true;
      },
      confirmRefund() {
        let url = "/user/rechargerecordrefund/insert";
        if (!this.reason) {
          return this.$message({
            type: "error",
            message: "请输入退款原因!",
          });
        }
        this.$axios
          .post(url, {
            user_id: this.temp.user_id,
            mechanism_id: this.temp.mechanism_id,
            recharge_record_id: this.temp.id,
            reason: this.reason,
            type: "退款",
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "处理成功!等待审核结果!",
              });
              this.temp = {};
              this.reason = null;
              this.refundReason = false;
            }
          });
      },
      handleClose(done) {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.refuseReason = false;
            this.showReason = false;
            this.refundReason = false;
            this.temp = {};
            done();
          })
          .catch((_) => {});
      },
      Uploadpayimg(file) {
        this.Recharge.payimg = file;
      },
    },
    watch: {
      mechanism_id(newV, oldV) {
        if (newV) {
          this.getPointList();
        }
      },
    },
  };
  </script>
  
  <style></style>
  